<template>
  <div class="container">
    <div class="container-body">
      <div :class="ClientWidth_number > 850 ? 'body-box' : 'body-box-phone'">
        <el-row>
          <el-col :span="6">
            <div
              class="grid-content bg-purple title"
              style="height: 60px; width: 228px"
            >
              <h3>资产脆弱性检测平台</h3>
              <div class="body-box2" v-if="ClientWidth_number < 500">
                <el-button class="pnone-button" type="primary" @click="jump()"
                  >试用申请</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div>
            <el-col :span="24">
              <div class="grid-content bg-purple content">
                <p>
                  赛尔安全结合多年的攻防实战经验，通过对攻防手段的技术研究积累，自主研发，推出资产脆弱性检测平台。在用户的资产遭受攻击前主动进行安全漏洞检测，降低资产遭受入侵和攻击的风险。
                </p>
                <p>
                  资产脆弱性检测平台采用深度的主机探测、智能化WEB爬虫、SQL注入状态检测、主机配置安全检查以及弱口令检查等方式相结合技术，通过大数据分析技术建立风险研判模型，提供WEB漏洞、系统漏洞、数据库漏洞、基线安全检测与弱口令检查等功能，提供专家级、有效的漏洞防护建议，实现资产安全漏洞全生命周期管理。
                </p>
              </div>
            </el-col>
          </div>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div
              class="grid-content bg-purple title"
              style="height: 25px; width: 130px"
            >
              <h3>产品功能</h3>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="ClientWidth_number > 850 ? 12 : 24">
            <div class="grid-content bg-purple content">
              <div class="content-box">
                <div style="width: 30%; height: 60%">
                  <img
                    src="../../assets/icon1.png"
                    style="width: 65px"
                    alt=""
                  />
                </div>
                <div style="width: 90%">
                  <div
                    style="
                      width: 97%;
                      height: 30%;
                      line-height: 200%;
                      font-weight: 1000;
                    "
                  >
                    Web安全检查
                  </div>
                  <div style="width: 100%; height: 70%; pdding: 10px">
                    针对SQL注入，XSS跨站脚本，敏感信息泄露，CSRF跨站，HTTP头注入，任意代码执行，目录遍历，文件上传漏洞等Web攻击方式进行检测。评估客户网站可能存在的各种Web安全隐患，避免“网站被黑”事件发生。
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="ClientWidth_number > 850 ? 12 : 24">
            <div class="grid-content bg-purple content">
              <div style="" class="content-box">
                <div style="width: 30%; height: 60%">
                  <img
                    src="../../assets/icon2.png"
                    style="width: 65px"
                    alt=""
                  />
                </div>
                <div style="width: 90%">
                  <div
                    style="
                      width: 97%;
                      height: 30%;
                      line-height: 200%;
                      font-weight: 1000;
                    "
                  >
                    系统安全检查
                  </div>
                  <div style="width: 100%; height: 70%; pdding: 10px">
                    通过丰富的内置漏洞特征库，采用启发式漏洞检测技术和低载荷探测手段，对于网络中的主机、网络设备、安全设备的安全脆弱性进行渗透性测试，发现可被利用的漏洞。
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="ClientWidth_number > 850 ? 12 : 24">
            <div class="grid-content bg-purple content">
              <div style="" class="content-box">
                <div style="width: 30%; height: 60%">
                  <img
                    src="../../assets/icon3.png"
                    style="width: 65px"
                    alt=""
                  />
                </div>
                <div style="width: 90%">
                  <div
                    style="
                      width: 97%;
                      height: 30%;
                      line-height: 200%;
                      font-weight: 1000;
                    "
                  >
                    安全基线检查
                  </div>
                  <div style="width: 100%; height: 70%; pdding: 10px">
                    使用各类配置指标，对比检查操作系统、WEB应用、中间件、数据库、网络基础设备等的配置信息，判断是否合规，存在配置安全风险。并给出加固建议。
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="ClientWidth_number > 850 ? 12 : 24">
            <div class="grid-content bg-purple content">
              <div style="" class="content-box">
                <div style="width: 30%; height: 50%">
                  <img
                    src="../../assets/icon4.png"
                    style="width: 80px"
                    alt=""
                  />
                </div>
                <div style="width: 90%">
                  <div
                    style="
                      width: 97%;
                      height: 30%;
                      line-height: 200%;
                      font-weight: 1000;
                    "
                  >
                    资产探测
                  </div>
                  <div style="width: 100%; height: 70%; pdding: 10px">
                    对于网络中主机、网络设备、安全设备、中间件、数据库等信息资产进行扫描，通过存活性探测、端口探测、OS探测等多种方式，发现信息资产的配置信息、端口开放情况，提供服务、运行的操作系统等综合信息。
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <!-- <el-col :span="ClientWidth_number > 850 ? 12 : 24">
            <div class="grid-content bg-purple content">
              <div style="" class="content-box">
                <div style="width: 35%; height: 60%">
                  <img src="../../assets/icon5.png" alt="" />
                </div>
                <div style="width: 90%">
                  <div
                    style="
                      width: 97%;
                      height: 30%;
                      line-height: 200%;
                      font-weight: 1000;
                    "
                  >
                    数据库漏洞扫描
                  </div>
                  <div style="width: 100%; height: 70%; pdding: 10px">
                     深入分析数据库系统本身存在的BUG，检测数据库系统本身安全性。
                  </div>
                </div>
              </div>
            </div>
          </el-col> -->
          <el-col :span="ClientWidth_number > 850 ? 12 : 24">
            <div class="grid-content bg-purple content">
              <div style="" class="content-box">
                <div style="width: 30%; height: 60%">
                  <img
                    src="../../assets/icon6.png"
                    style="width: 65px"
                    alt=""
                  />
                </div>
                <div style="width: 90%">
                  <div
                    style="
                      width: 97%;
                      height: 30%;
                      line-height: 200%;
                      font-weight: 1000;
                    "
                  >
                    弱口令探测
                  </div>
                  <div style="width: 100%; height: 70%; pdding: 10px">
                    内置了针对国内常用命名习惯的弱口令库，同时支持基于各种主流口令生成规则实现口令匹配扫描，提供基于字典库，基于规则，基于穷举等多种模式下的弱口令检测，同时支持通过代理切换，自适应调整探测强度等方式，规避异常登录的用户锁定问题和检查效率问题。
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div
              class="grid-content bg-purple title"
              style="height: 25px; width: 128px"
            >
              <h3>产品特点</h3>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <div class="grid-content bg-purple content">
              <div>
                检测更全面：可以检测类型多，支持网站漏洞扫描、基线核查、数据库漏洞扫描、系统及软件漏洞扫描、端口及服务探测、弱口令扫描等；
              </div>
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple content">
              结果更准确：丰富的特征库涵盖所有主流基础系统、应用系统、网络设备等网元对象，同时引入专家级的人工复审流程，结果呈现更准确。
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple content">
              服务更灵活：检测报告定制化，检测任务周期定制化，检测级别定制化。
            </div>
          </el-col>
          <el-col :span="24">
            <div class="grid-content bg-purple content">
              检测更负责：脆弱性全生命周期全程化、主动化管理。
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <div
              v-if="ClientWidth_number > 850"
              class="grid-content bg-purple title"
              style="height: 70px; width: 10vw"
            ></div>
          </el-col>
        </el-row>
      </div>
      <div class="body-box2" v-if="ClientWidth_number > 850">
        <el-button class="pc-button" type="primary" @click="jump()"
          >试用申请</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xxx: "\u3000\u3000",
      ClientWidth_number: document.documentElement.clientWidth,
    };
  },
  created() {
    this.getClientWidth();
  },
  methods: {
    jump() {
      this.$nextTick(() => {
        window.location.href = "https://vss.cersnet.com";
      });
    },
    getClientWidth() {
      window.onresize = () => {
        this.ClientWidth_number = document.documentElement.clientWidth;
      };
    },
  },
};
</script>

<style lang='less' scoped>
@baseWidth: 1980;
.pxfix(@w) {
  width: (@w / @baseWidth) * 100vw;
}
.content-box {
  min-height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.container-body {
  .pxfix(1980);
  min-height: 700px;
  margin: 0 auto;
  background: #eff3f8;
  // margin-top: 20px;
  display: flex;
  flex: 1;
  .title {
    font-size: 1.2rem;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-weight: normal;
    font-family: "microsoft yahei";
    color: #555;
  }
  .content {
    margin: 0 1rem;
    color: #555;
    font-size: 0.8rem;
    line-height: 25px;
    font: "microsoft yahei";
  }
  .content-phone {
    margin: 0 1rem;
    color: #555;
    font-size: 3vw;
    line-height: 25px;
    font: "microsoft yahei";
  }
  .body-box {
    .pxfix(1040);
    margin: 1.5rem auto;
    //  flex:1;
    padding: 0 3vw;
    background: #fff;
  }
  .body-box-phone {
    background: #fff;
    margin: 1.5rem auto;
  }
  .body-box:hover {
    box-shadow: 0 0 10px #888888;
  }
}
.footer {
  line-height: 50px;
  background: #2f3034;
  width: 100%;
  font-size: 12px;
  color: #fff;
  text-align: center;
}
</style>
<style>
.body-box2 > .pc-button {
  font-size: 20px;
  width: 150px !important;
  height: 60px !important;
  position: fixed;
  top: 150px;
  right: 5vw;
}
.body-box2 > .pnone-button {
  /* font-size: 20px; */
  /* width: 150px !important;
  height: 60px !important; */
  position: fixed;
  z-index: 99;
  font-weight: 600;
  top: 100px;
  right: 0;
}
</style>